import styled from 'styled-components';

import { CMSBlockContainer } from '@common/components/CMSComponents/Container.styled';

export const Container = styled(CMSBlockContainer)`
  h2 + p,
  h2 + ol,
  h2 + ul,
  h4 + p,
  h4 + ol,
  h4 + ul {
    ${({ theme }) => `${theme.getFluidSpacing('margin-top', 'scale', 4)}`};
  }

  ol + ol,
  ol + ul,
  ol + p,
  p + p,
  p + ol,
  p + ul,
  ul + p,
  ul + ol,
  ul + ul,
  li + li {
    ${({ theme }) => theme.getFluidSpacing('margin-top', 'equal', 2)};
  }

  p + h2,
  ol + h2,
  ul + h2 {
    ${({ theme }) => theme.getFluidSpacing('margin-top', 'scale', 0)};
  }

  p + h4,
  ol + h4,
  ul + h4 {
    ${({ theme }) => theme.getFluidSpacing('margin-top', 'scale', 1)};
  }

  h2 + h2,
  h2 + h4,
  h4 + h4,
  h4 + h2 {
    ${({ theme }) => theme.getFluidSpacing('margin-top', 'scale', 3)};
  }
`;
