import styled from 'styled-components';

import Typography from '@common/components/Typography';

export const Container = styled.div`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 16)};
`;

export const Title = styled(Typography).attrs({
  variant: 'h4',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-botton', 'scale', 5)};
`;

export const Subtitle = styled(Typography).attrs({
  variant: 'paragraph1',
})`
  font-weight: bold;
`;
