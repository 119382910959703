import { LANGUAGE_DE, LANGUAGE_FR } from '@common/constants/languages';

export const GERMAN_STORE_FINDER_ALL = 'alle';
export const GERMAN_STORE_FINDER_FIND = 'suche';
export const GERMAN_STORE_FINDER_ROUTES = [GERMAN_STORE_FINDER_ALL, GERMAN_STORE_FINDER_FIND];

export const FRENCH_STORE_FINDER_ALL = 'tous';
export const FRENCH_STORE_FINDER_FIND = 'recherche';
export const FRENCH_STORE_FINDER_ROUTES = [FRENCH_STORE_FINDER_ALL, FRENCH_STORE_FINDER_FIND];

export const SEARCH_MODE = {
  TEXT: 'text',
  GEOLOCATION: 'geolocation',
};

export const GEOLOCATION_PERMISSION_STATUS = {
  GRANTED: 'granted',
  PROMPT: 'prompt',
  DENIED: 'denied',
  ERROR: 'error', // custom
};

export const GEOLOCATION_PERMISSION_STATUSES = Object.values(GEOLOCATION_PERMISSION_STATUS);

export const getStoreFinderRoutesByLanguage = language => {
  switch (language) {
    case LANGUAGE_FR:
      return FRENCH_STORE_FINDER_ROUTES;

    case LANGUAGE_DE:
    default:
      return GERMAN_STORE_FINDER_ROUTES;
  }
};
