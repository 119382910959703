import styled from 'styled-components';

import { getThemeColor } from '@utils/styled';

import Typography from '@common/components/Typography';
import { IconButton } from '@common/components/IconButton';

export const ShowAllItems = styled.a`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100%;
  background-color: ${getThemeColor('bluegrey.95')};
  color: inherit;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

export const ShowAllItemsContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ShowAllItemsIcon = styled(IconButton).attrs({
  customAs: 'span',
})`
  margin: auto;

  svg {
    display: block;
    fill: ${getThemeColor('bluegrey.80')};
  }
`;

export const ShowAllItemsText = styled(Typography).attrs({
  variant: 'paragraph1',
})`
  margin-top: 0.4rem;
`;
