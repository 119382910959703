import { distance } from '@utils/math';

import { geoLocation } from '@common/endpoints';

import { convertToZurichTz, getDateZurich } from './date';

const dayToIdxMap = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

export const dayToTranslationKey = {
  mon: 'monday',
  tue: 'tuesday',
  wed: 'wednesday',
  thu: 'thursday',
  fri: 'friday',
  sat: 'saturday',
  sun: 'sunday',
};

export const getCurrentDayNameShort = () => dayToIdxMap[getDateZurich().getDay()];

export const getBusinessHoursDates = (openingHoursRange, now) => {
  const { openTime, closeTime } = openingHoursRange;

  if (!openTime || !closeTime) return [];

  if (openTime === 'unknown' || closeTime === 'unknown') return [null, null];

  const currDate = [now.getFullYear(), now.getMonth(), now.getDate()];
  const openHours = openTime.split(':', 2);
  const closeHours = closeTime.split(':', 2);

  const openDate = convertToZurichTz(new Date(...currDate, ...openHours));
  const closeDate = convertToZurichTz(new Date(...currDate, ...closeHours));

  return [openDate, closeDate];
};

export const getStoreOpeningInfo = businessHours => {
  const now = getDateZurich();
  const todayBusinessHours = businessHours[dayToIdxMap[now.getDay()]];

  if (!todayBusinessHours.length) {
    return {
      isStoreOpen: false,
      openTime: null,
      closeTime: null,
    };
  }

  const activeOpeningHoursIndex = todayBusinessHours.findIndex(item => {
    const [openDate, closeDate] = getBusinessHoursDates(item, now);

    return now.getTime() >= openDate.getTime() && now.getTime() <= closeDate.getTime();
  });

  const { openTime, closeTime } = todayBusinessHours[
    activeOpeningHoursIndex !== -1 ? activeOpeningHoursIndex : 0
  ];

  const splittedOpenTime = openTime.split(':');
  const splittedCloseTime = closeTime.split(':');

  return {
    isStoreOpen: activeOpeningHoursIndex !== -1,
    openTime: {
      hour: splittedOpenTime[0],
      minute: splittedOpenTime[1],
    },
    closeTime: {
      hour: splittedCloseTime[0],
      minute: splittedCloseTime[1],
    },
  };
};

const businessHoursToEntries = ({
  mon, tue, wed, thu, fri, sat, sun,
}) => [
  ['sun', sun],
  ['mon', mon],
  ['tue', tue],
  ['wed', wed],
  ['thu', thu],
  ['fri', fri],
  ['sat', sat],
];

export const getClosestOpeningDate = unorderedBusinessHours => {
  const now = getDateZurich();
  const nowTime = now.getTime();
  const nowDay = now.getDay();
  const businessHours = businessHoursToEntries(unorderedBusinessHours);

  let isOpeningToday = false;
  let dayKey = dayToIdxMap[nowDay];
  let closestOpeningTime = businessHours[nowDay][1].find(openingHoursRange => {
    const [, closeDate] = getBusinessHoursDates(openingHoursRange, now);

    if (closeDate === null) return undefined;

    return nowTime <= closeDate.getTime();
  });

  if (typeof closestOpeningTime === 'undefined') {
    return {
      dayTranslationKey: dayToTranslationKey[dayKey],
      openTime: {
        hour: 'unknown',
        minute: 'unknown',
      },
      closeTime: {
        hour: 'unknown',
        minute: 'unknown',
      },
      isOpeningToday,
    };
  }

  if (Boolean(closestOpeningTime) === true) {
    isOpeningToday = true;
  }

  // TBD if we need to find the next opening time
  if (Boolean(closestOpeningTime) === false) {
    [dayKey, [closestOpeningTime]] = [
      ...businessHours.slice(nowDay + 1),
      ...businessHours.slice(0, nowDay + 1),
    ].find(([, openingHours]) => (openingHours ? openingHours.length : undefined));
  }

  const [openHour, openMinute] = closestOpeningTime.openTime.split(':');
  const [closeHour, closeMinute] = closestOpeningTime.closeTime.split(':');

  return {
    dayTranslationKey: dayToTranslationKey[dayKey],
    openTime: {
      hour: openHour,
      minute: openMinute,
    },
    closeTime: {
      hour: closeHour,
      minute: closeMinute,
    },
    isOpeningToday,
  };
};

export const getOpeningInfoCaption = (t, closeTime, isStoreOpen, closestOpeningDate) => {
  if (!isStoreOpen) {
    const interpolationValues = {
      hour: closestOpeningDate.openTime.hour,
      minute: closestOpeningDate.openTime.minute,
      day: t(closestOpeningDate.dayTranslationKey),
    };

    if (closestOpeningDate.isOpeningToday) {
      return t(
        'storefinder.storeCard.opensAtTime',
        'Öffnet um {{hour}}:{{minute}} Uhr',
        interpolationValues
      );
    }

    if (
      closestOpeningDate.openTime.hour === 'unknown' ||
      closestOpeningDate.openTime.minute === 'unknown'
    ) {
      return t('storefinder.storeCard.opensAtDate', 'Öffnet am {{day}}', {
        day: interpolationValues.day,
      });
    }

    return t(
      'storefinder.storeCard.opensAtDateTime',
      'Öffnet am {{day}} um {{hour}}:{{minute}} Uhr',
      interpolationValues
    );
  }

  if (closeTime.hour === 'unknown' || closeTime.minute === 'unknown') {
    return '';
  }

  return t('storefinder.storeCard.closesAtTime', 'Schliesst um {{hour}}:{{minute}} Uhr', {
    hour: closeTime.hour,
    minute: closeTime.minute,
  });
};

export const debounceAsync = (func, wait) => {
  let timerID = -1;

  return (...args) => {
    clearTimeout(timerID);

    const promiseForFunc = new Promise(resolve => {
      timerID = setTimeout(resolve, wait);
    });

    return promiseForFunc.then(() => func(...args));
  };
};

export const MAX_STORES_DISTANCE = 15;

export const getLatAndLngFromQuery = async body =>
  fetch(geoLocation(), {
    method: 'POST',
    body: JSON.stringify(body),
  }).then(res => res.json());
export const sortStoresByDistance = (storeA, storeB) => storeA.distance - storeB.distance;

const queryablePartialKeys = ['zipCode', 'city'];
const queryableMatchesKeys = ['street'];
const checkPartialQuery = (store, query) =>
  queryablePartialKeys.some(key => store[key].toLowerCase().includes(query));
const checkStoreMatchedQuery = (store, query) =>
  queryableMatchesKeys.some(key => store[key].toLowerCase() === query);

export const selectShopsByQuery = (query, stores) => {
  const querySanitize = query.toLowerCase().trim();
  const byCityOrZipCodeResults = Object.values(stores).filter(store =>
    checkPartialQuery(store, querySanitize));

  if (byCityOrZipCodeResults.length) {
    return byCityOrZipCodeResults;
  }

  return Object.values(stores).filter(store => checkStoreMatchedQuery(store, querySanitize));
};

export const selectNearbyShops = (lat, lng, maxDistance = 15, stores) =>
  Object.values(stores)
    .reduce((acc, store) => {
      const storeDistance = distance(lat, lng, store.lat, store.lng);

      if (storeDistance <= maxDistance) {
        acc.push({ ...store, distance: storeDistance });
      }

      return acc;
    }, [])
    .sort(sortStoresByDistance);
