import styled from 'styled-components';

import { CMSBlockContainer } from '@common/components/CMSComponents/Container.styled';
import Typography from '@common/components/Typography';

export const AccordionBlockStyled = styled(CMSBlockContainer).attrs({
  shouldUseFixedTypography: false,
})``;

export const Title = styled(Typography).attrs({
  variant: 'h4',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 4)};
`;

export const Intro = styled.div`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 4)};
`;

export const ItemsContainer = styled.div``;
