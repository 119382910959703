import React from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { getRouteUrl } from '@utils/urls';

import { SeeMoreBonsWrapper } from '@common/components/Voucher/Bon/Bon.styled';
import { VIP_VOUCHERS } from '@common/routes';
import {
  ChevronButton,
  SeeMoreTitle,
} from '@common/components/VouchersCarouselBase/VouchersCarouselBase.styled';
import { Chevron } from '@common/components/Icons';

const SeeMoreBons = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const router = useRouter();

  return (
    <SeeMoreBonsWrapper onClick={() => router.push(getRouteUrl(language, VIP_VOUCHERS))}>
      <ChevronButton
        direction="right"
        isWhite
        isFlipped
        onClick={() => router.push(getRouteUrl(language, VIP_VOUCHERS))}
      >
        <Chevron />
      </ChevronButton>
      <SeeMoreTitle>{t('bonsCarousel.butttonText', 'Alle ansehen')}</SeeMoreTitle>
    </SeeMoreBonsWrapper>
  );
};

export default SeeMoreBons;
