import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

import { loadFlowbox } from './loadFlowbox';
import { SectionWrapper, Title } from './FlowboxWidgetBlock.styled';

const containerID = 'js-flowbox-flow';

const Flowbox = ({ value: { title, flowboxKey } }) => {
  const { locale } = useRouter();

  useEffect(() => {
    (async () => {
      await loadFlowbox();

      window.flowbox('init', {
        container: `#${containerID}`,
        key: flowboxKey,
        localeWithRegion: `${locale}-CH`,
      });
    })();
  }, [flowboxKey, locale]);

  return (
    <SectionWrapper>
      <Title>{title}</Title>
      <div id={containerID} />
    </SectionWrapper>
  );
};

Flowbox.propTypes = {
  value: PropTypes.shape({
    title: PropTypes.string.isRequired,
    flowboxKey: PropTypes.string.isRequired,
  }).isRequired,
};

export default Flowbox;
