import styled from 'styled-components';

import { getThemeColor } from '@utils/styled';

import Typography from '@common/components/Typography';
import { COUPON_BG_PATTERN } from '@common/images';

export const BonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;
  background-color: ${getThemeColor('grey.0')};
  background-image: ${`url(${COUPON_BG_PATTERN})`};
  background-position: 50% 50%;
  color: white;
`;

export const PriceTag = styled(Typography).attrs(({ variant }) => ({
  customAs: 'h2',
  variant: variant || 'h1',
}))`
  margin: 0;
  background: ${getThemeColor('brand.goldGradient')};
  -webkit-text-fill-color: transparent;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-background-clip: text;
  background-clip: text;
`;

export const CardButton = styled.button`
  width: 100%;
  border: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
`;

export const ValidCardDetailsPriceTag = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${getThemeColor('grey.0')};
`;

export const SeeMoreBonsWrapper = styled(BonWrapper)`
  background-color: ${getThemeColor('bluegrey.92')};
  background-image: none;
  background-position: unset;
  cursor: pointer;
`;
