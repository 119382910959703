import PropTypes from 'prop-types';
import React from 'react';

import HTMLRenderer from '@common/components/CMSComponents/HTMLRenderer';
import LazyImg from '@common/components/LazyImg/LazyImg';

import {
  Container,
  Intro,
  Item,
  ItemContent,
  ItemImage,
  ItemsContainer,
  Title,
} from './ImageTextAlternateList.styled';

const ImageTextAlternateList = ({ value }) => {
  const { title, intro, items } = value;

  return (
    <Container>
      {title && <Title>{title}</Title>}
      {intro && (
        <Intro>
          <HTMLRenderer html={intro} />
        </Intro>
      )}
      <ItemsContainer>
        {items.map(({ text, image }, index) => (
          <Item
            alternate={index % 2}
            key={text}
          >
            <ItemImage>
              <LazyImg
                alt={image?.altText || ''}
                src={image?.url}
                widths={[500, 450, 380, 430, 360, 280, 570, 420, 320]}
                aspectRatios={`${+(image.width / image.height).toFixed(3)}:1`}
              />
            </ItemImage>
            <ItemContent>
              <HTMLRenderer html={text} />
            </ItemContent>
          </Item>
        ))}
      </ItemsContainer>
    </Container>
  );
};

ImageTextAlternateList.propTypes = {
  value: PropTypes.shape({
    intro: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          altText: PropTypes.string,
          url: PropTypes.string,
        }),
        text: PropTypes.string,
      })
    ),
    title: PropTypes.string,
  }).isRequired,
};

export default ImageTextAlternateList;
