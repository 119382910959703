import styled from 'styled-components';

import { media } from '@utils/styled';

import Typography from '@common/components/Typography';
import { CMSBlockContainer } from '@common/components/CMSComponents/Container.styled';

export const Container = styled(CMSBlockContainer)``;

export const IconFactGridBlockWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 3rem;
  ${({ theme }) => theme.getFluidSpacing('grid-row-gap', 'scale', 1)};

  ${media.from640up`
    grid-template-columns: 1fr 1fr;
  `}
`;

export const FactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FactTitle = styled(Typography).attrs({
  variant: 'h5',
})`
  margin: 0;
  font-weight: 700;
  text-align: center;
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'scale', 5)};
`;

export const FactSubTitle = styled(Typography).attrs({
  variant: 'paragraph1',
})`
  margin: 0;
  font-weight: 500;
  text-align: center;
`;
