import styled from 'styled-components';

import { centeredGridColumns, media } from '@utils/styled';

import Typography from '@common/components/Typography';

export const Heading = styled(Typography).attrs({ variant: 'h4' })`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 1)};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: ${centeredGridColumns(12)};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 1)};
  ${({ theme }) => theme.getFluidSpacing('margin-right', 'widthBased', 0, true)};
  ${({ theme }) => theme.getFluidSpacing('margin-left', 'widthBased', 0, true)};

  ${media.from1040down`
    margin-left: 0;
    margin-right: 0;
  `}

  ${media.from1040up`
    ${({ theme }) => theme.getFluidSpacing('padding-left', 'widthBased', 0)};
    ${({ theme }) => theme.getFluidSpacing('padding-right', 'widthBased', 0)};
  `};
`;

export const Grid = styled.div`
  display: grid;
  ${({ theme }) => theme.getFluidSpacing('grid-row-gap', 'scale', 4)};
  grid-template-columns: 1fr;

  ${media.from640up`
    grid-template-columns: repeat(2, 1fr);
    ${({ theme }) => theme.getFluidSpacing('grid-gap', 'widthBased', 1)};
  `};

  ${media.from1040up`
    grid-template-columns: repeat(3, 1fr);
  `};
`;
