import styled from 'styled-components';

import { getGridBase, media } from '@utils/styled';

import { CMSBlockContainer } from '@common/components/CMSComponents/Container.styled';
import Typography from '@common/components/Typography';

export const Container = styled(CMSBlockContainer)`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 1)};
`;

export const Title = styled(Typography).attrs({
  customAs: 'h3',
  variant: 'h3',
})``;

export const Intro = styled(Typography).attrs({
  customAs: 'div',
  variant: 'subtitle2',
})`
  font-weight: 400;

  ${/* sc-sel */ Container} & {
    ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 4)};
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  ${getGridBase(2)};
  grid-template-areas: 'image text';
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 1)};

  &:last-child {
    margin-bottom: 0;
  }

  &:nth-child(even) {
    grid-template-areas: 'text image';
  }

  ${media.from640down`
      display: flex;
      flex-direction: column;
  `}
`;

export const ItemImage = styled.div`
  grid-area: image;

  ${media.from640down`
    ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 4)};
  `}
`;

export const ItemContent = styled.div`
  justify-content: center;
  align-self: center;
  grid-area: text;
`;
