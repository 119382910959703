import styled from 'styled-components';

import { media } from '@utils/styled';

export const Container = styled.div`
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'scale', 3, true)};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'section', 3)};

  .CookieDeclarationIntro {
    display: none;
  }

  .CookieDeclarationDialogText {
    display: none;
  }

  ${media.from800down`
    .CookieDeclarationType{
      overflow-x: auto;
    }

    .CookieDeclarationTable{
      min-width: 600px;
    }
  `}
`;
