import PropTypes from 'prop-types';
import React from 'react';

import {
  Container,
  Item,
  ItemsContainer,
  ItemSubtitle,
  ItemTitle,
  Title,
} from './TabularList.styled';

const TabularList = ({ value }) => {
  const { title, rows } = value;

  return (
    <Container>
      <Title>{title}</Title>
      <ItemsContainer>
        {rows.map(row => (
          <Item key={row.title}>
            <ItemTitle>{row.title}</ItemTitle>
            {row.subtitle && <ItemSubtitle>{row.subtitle}</ItemSubtitle>}
          </Item>
        ))}
      </ItemsContainer>
    </Container>
  );
};

TabularList.propTypes = {
  value: PropTypes.shape({
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        subtitle: PropTypes.string,
        title: PropTypes.string,
      })
    ),
    title: PropTypes.string,
  }).isRequired,
};

export default TabularList;
