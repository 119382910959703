import endOfDay from 'date-fns/endOfDay';

import { convertToZurichTz, getDateZurich } from '@utils/date';

export const selectVouchers = state => state.vouchers.vouchers.results;
export const selectActiveVouchers = state =>
  state.vouchers.vouchers.results.filter(
    ({ validUntil, dateUsed }) =>
      !(endOfDay(getDateZurich()) > convertToZurichTz(endOfDay(new Date(validUntil)))) && !dateUsed
  );
export const selectVouchersHasMore = state => state.vouchers.vouchers.hasMore;
export const selectVouchersListOffset = state => state.vouchers.vouchers.offset;
export const selectIsFetchingVouchers = state => state.vouchers.vouchers.isFetching;
export const selectInfoModalTitle = state => state.vouchers.infoModal.data?.title;
export const selectInfoModalBody = state =>
  state.vouchers.infoModal.data?.body?.[0]?.value.items || [];
