import styled from 'styled-components';

import { getThemeColor, media } from '@utils/styled';

import Typography from '@common/components/Typography';
import LazyImg from '@common/components/LazyImg';

export const MobilePlanBlockWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  margin: 0 auto;
  border-radius: ${({ theme, asHero }) => (!asHero ? theme.borderRadiuses['normal.1'] : 0)}rem;
  background-color: ${getThemeColor('grey.100')};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 1)};

  ${media.from800up`
    flex-direction: ${({ reverseLayout }) => (reverseLayout ? 'row-reverse' : 'row')};
    margin-right: 0;
    margin-left: 0;
    border-radius: ${({ theme }) => theme.borderRadiuses['normal.1']}rem;
  `}
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => theme.getFluidSpacing('margin', 'widthBased', 0)};

  ${media.from800up`
    width: 45%;
    order: ${({ isReverse }) => (isReverse ? '0' : '-1')};
    ${({ theme }) => theme.getFluidSpacing('margin', 'widthBased', 2)};
    ${({ theme }) => theme.getFluidSpacing('margin-right', 'widthBased', 3)};
  `}

  ${media.from1200up`
    width: 40%;
  `}
`;

export const MobilePlanBlockImage = styled(LazyImg)`
  position: relative;
  min-height: 100%;

  img {
    height: 100%;
    background-color: ${getThemeColor('bluegrey.90')};
    object-fit: cover;
  }

  ${media.from800up`
    picture {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  `}
`;

export const ImageLink = styled.a`
  position: relative;
  display: block;
  width: 100%;

  ${media.from800up`
    width: 55%;
  `}

  ${media.from1200up`
    width: 60%;
  `}
`;

export const HighlightsLabel = styled(Typography).attrs({
  variant: 'body2',
})`
  color: ${getThemeColor('bluegrey.60')};
  font-weight: 500;
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'scale', 7)};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'equal', 6)};
`;

export const HighlightsWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 4)};
`;

export const Highlight = styled(Typography).attrs({
  variant: 'paragraph1',
  customAs: 'li',
})`
  margin: 0;
  font-weight: 400;
  line-height: 180%;
`;

export const Title = styled(Typography).attrs({
  variant: 'h5',
})``;

export const Price = styled(Typography).attrs({
  variant: 'h4',
})``;

export const PriceAdditionalInfo = styled(Typography).attrs({
  variant: 'body1',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 4)};
  color: ${getThemeColor('bluegrey.60')};
  font-weight: 700;
`;

export const Badge = styled.span`
  position: absolute;
  z-index: 1;
  ${({ theme }) => theme.getFluidSpacing('top', 'scale', 6)};
  ${({ theme }) => theme.getFluidSpacing('left', 'scale', 6)};
  ${({ theme }) => theme.getFluidSpacing('width', 'scale', 14)};
  ${({ theme }) => theme.getFluidSpacing('height', 'scale', 14)};
`;

export const Heading = styled(Typography).attrs({
  variant: 'h4',
})`
  ${({ theme }) => theme.getFluidSpacing('padding-bottom', 'scale', 3)};
  margin: 0;
`;
