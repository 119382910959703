import styled from 'styled-components';

import { getGridBase, getThemeColor, media } from '@utils/styled';

import { BUTTON_VARIANTS, ButtonLink } from '@common/components/Button';
import Typography from '@common/components/Typography';
import { CMSBlockContainer } from '../Container.styled';

export const Container = styled(CMSBlockContainer)``;

export const Title = styled(Typography).attrs({
  variant: 'h4',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 4)};
`;

export const ItemsContainer = styled.div`
  ${getGridBase()}
  ${({ theme }) => theme.getFluidSpacing('grid-gap', 'widthBased', 1)};
`;

export const Item = styled.div`
  grid-column: span 12;
  border: 1px solid ${getThemeColor('bluegrey.90')};
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;
  background-color: ${getThemeColor('bluegrey.95')};
  ${({ theme }) => theme.getFluidSpacing('padding', 'scale', 3)};
  ${media.from800up`
    grid-column: span 6;
  `}
`;

export const ItemDate = styled(Typography).attrs({
  customAs: 'div',
  variant: 'body4',
})`
  color: ${getThemeColor('bluegrey.60')};
  font-weight: 500;
`;
export const ItemSubtitle = styled(Typography).attrs({
  customAs: 'div',
  variant: 'body4',
})`
  color: ${getThemeColor('bluegrey.60')};
  font-weight: 500;
`;

export const ItemTitle = styled(Typography).attrs({
  customAs: 'h5',
  variant: 'h6',
})``;

export const ItemText = styled.div``;

export const Description = styled.div`
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'widthBased', 1)};
`;

export const ItemButton = styled(ButtonLink).attrs({
  variant: BUTTON_VARIANTS.LEVEL_2_GREY_20,
})`
  a&[href]::after {
    content: none;
    display: none;
  }
`;
