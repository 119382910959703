import styled from 'styled-components';

import { getThemeColor, getGridBase, media } from '@utils/styled';

import Typography from '@common/components/Typography';
import { CMSBlockContainer } from '@common/components/CMSComponents/Container.styled';

export const Wrapper = styled(CMSBlockContainer)``;

export const OptionCardWrapper = styled.div`
  ${getGridBase(12)}
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: span 12;
  width: 100%;
  border: 1px solid ${getThemeColor('bluegrey.80')};
  border-radius: 12px;
  ${({ theme }) => theme.getFluidSpacing('padding', 'scale', 3)};

  ${({ singleItem }) =>
    !singleItem &&
    media.from800up`
      grid-column: span 6;
    `}
`;

export const AdditionalInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'scale', 5)};
  border-top: 1px solid ${getThemeColor('bluegrey.90')};
  ${({ theme }) => theme.getFluidSpacing('padding-top', 'equal', 7)};
`;

export const Heading = styled(Typography).attrs({ variant: 'h4' })`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 3)};
`;

export const SchoolYears = styled(Typography).attrs({ variant: 'h4' })``;

export const Position = styled(Typography).attrs({ variant: 'h6' })`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 7)};
`;

export const Certification = styled(Typography).attrs({ variant: 'body2' })`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'equal', 7)};
  color: ${getThemeColor('bluegrey.60')};
  font-weight: 500;
`;
