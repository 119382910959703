import styled, { createGlobalStyle } from 'styled-components';

import { getGridBase, media } from '@utils/styled';

import LazyImg from '@common/components/LazyImg';

export const GlobalStyle = createGlobalStyle`
  :root {
    --nav-height: 6rem;
  }

  body,
  body.scrolling-down {
    --nav-height: 0rem;
  }

  body.scrolling-up {
    --nav-height: 6rem;
  }
`;

export const Container = styled.div`
  ${getGridBase()}
  ${({ theme }) => theme.getFluidSpacing('margin-left', 'widthBased', 0, true)};
  ${({ theme }) => theme.getFluidSpacing('margin-right', 'widthBased', 0, true)};

  ${media.from640up`
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  `}
`;

export const ImageContainer = styled.div`
  display: none;
  grid-column: span 12;

  ${media.from800up`
    display: block;
    grid-column: span 7;
  `};

  ${media.from1040up`
    display: block;
    grid-column: span 8;
  `};
`;

export const ResultsContainer = styled.div`
  grid-column: span 12;

  ${media.from800up`
    grid-column: span 5;
  `};

  ${media.from1040up`
    grid-column: span 4;
  `};
`;

export const StickyContainer = styled.div`
  --height: min(
    100svh,
    clamp(50rem, 9.6354166667rem + 79.16666666666667vw, 164rem)
  ); /* 500px - 1640px */
  --top-bar: 6rem;
  --padding: clamp(2rem, 0.6076388889rem + 2.2222222222vw, 4rem); /* 20px - 40px */
  position: sticky;
  top: 6rem;
  display: block;
  height: calc(100vh - 6rem);
  line-height: 0;

  ${media.from320up`
    top: calc(50svh - (var(--height) / 2) + var(--nav-height) + var(--top-bar) + var(--padding));
    height: calc(var(--height) - var(--nav-height) - var(--top-bar) - var(--padding) * 2);
    transition: height 0.2s ease, top 0.2s ease;
  `};
`;

export const Item = styled.div`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 1)};

  ${media.from480up`
    grid-column: span 12;
  `}

  ${media.from640up`
    grid-column: 2 / span 10;
  `}
`;

export const ItemsList = styled.div`
  ${media.from480up`
    ${getGridBase()}
    ${({ theme }) => theme.getFluidSpacing('margin-left', 'widthBased', 0)};
    ${({ theme }) => theme.getFluidSpacing('margin-right', 'widthBased', 0)};
  `}

  ${media.from640up`
    margin-left: 0;
    margin-right: 0;
  `}

  ${media.from800up`
    display: block;
  `}

  &:last-child ${/* sc-selector */ Item}:last-child {
    margin-bottom: 0;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SliderImage = styled(LazyImg)`
  display: block;
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 1)};

  ${media.from800up`
    display: none;
  `}
`;

export const ButonWrapper = styled.div`
  display: flex;
  justify-content: center;
  grid-column: span 12;
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'section', 3)};

  a::after {
    content: none !important;
  }
`;
