import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import ResizeObserver from 'resize-observer-polyfill';

const useCollapsingElementHeight = ref => {
  const [height, setHeight] = useState('100%');

  useEffect(() => {
    const handleResize = entries => {
      if (entries?.length) {
        setHeight(`${entries[0].target.scrollHeight}px`);
      }
    };

    const observedElement = ref.current;
    const sectionObserver = new ResizeObserver(debounce(handleResize, 300));

    handleResize();

    sectionObserver.observe(observedElement);

    return () => sectionObserver.unobserve(observedElement);
  }, [ref]);

  return height;
};

export default useCollapsingElementHeight;
