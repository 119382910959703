import React from 'react';
import PropTypes from 'prop-types';
import { usePrevious } from 'react-use';

import { SQUARE } from '@utils/image';

import { Container, StyledLazyImg, ActiveImage } from './CrossFadeImages.styled';

const CrossFadeImages = ({
  imagesUrls, breakpoints, activeIndex, duration, className, widths,
}) => {
  const prevIndex = usePrevious(activeIndex);

  return (
    <Container className={className}>
      {imagesUrls.map((url, index) => (
        <StyledLazyImg
          src={url}
          key={`${url}-${index}` /* eslint-disable-line */}
          alt=""
          breakpoints={breakpoints}
          aspectRatios={SQUARE}
          widths={widths}
          forceShow
          duration={duration}
          visible={index === activeIndex}
          previous={index === prevIndex}
        />
      ))}
      <ActiveImage
        src={imagesUrls[prevIndex || 0]}
        alt=""
        breakpoints={breakpoints}
        aspectRatios={SQUARE}
        widths={widths}
        forceShow
      />
    </Container>
  );
};

CrossFadeImages.propTypes = {
  activeIndex: PropTypes.number,
  breakpoints: PropTypes.arrayOf(PropTypes.number),
  className: PropTypes.string,
  duration: PropTypes.number,
  imagesUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  widths: PropTypes.arrayOf(PropTypes.number),
};

CrossFadeImages.defaultProps = {
  activeIndex: 0,
  className: undefined,
  duration: 500,
  widths: undefined,
  breakpoints: undefined,
};

export default CrossFadeImages;
