import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

import SingleLookbookSliderItem from '@common/components/CMSComponents/LookbookProductsListSlider/SingleLookbookSliderItem';
import LoadingIndicator from '@common/components/LoadingIndicator';

import { ItemsList, LoadingContainer, SliderImage } from './LookbookProductsListSlider.styled';

const SingleLookbookSlider = ({
  isFetchingProducts,
  products,
  sliderIndex,
  image,
  productsIndexStartFrom,
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      {isFetchingProducts && (
        <LoadingContainer>
          <LoadingIndicator
            isLarge
            withWrapper
          />
        </LoadingContainer>
      )}
      <SliderImage
        src={image}
        alt={t('singleLookbook.LookbookHeadingImageAlt', 'product')}
        breakpoints={[800, 480, 400, 320]}
        widths={[800, 640, 480, 400]}
        aspectRatios={['4:3', '1:1', '3.2:4', '3.2:4']}
      />
      {!isFetchingProducts && (
        <ItemsList>
          {products.map((product, index) => (
            <SingleLookbookSliderItem
              key={product.productColorId}
              productIndex={productsIndexStartFrom + index}
              product={product}
              sliderIndex={sliderIndex}
            />
          ))}
        </ItemsList>
      )}
    </Fragment>
  );
};

SingleLookbookSlider.propTypes = {
  isFetchingProducts: PropTypes.bool,
  image: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
    })
  ).isRequired,
  sliderIndex: PropTypes.number.isRequired,
  productsIndexStartFrom: PropTypes.number.isRequired,
};

SingleLookbookSlider.defaultProps = {
  isFetchingProducts: false,
};

export default SingleLookbookSlider;
