import PropTypes from 'prop-types';
import React from 'react';

import StoreInfo, { storeInfoPropTypes } from '@common/components/StoreInfo';

import { Container } from './StoreInformationBlock.styled';

const StoreInformationBlock = ({ value }) => {
  const { store } = value;

  return (
    <Container>
      <StoreInfo
        {...store}
        description=""
      />
    </Container>
  );
};

StoreInformationBlock.propTypes = {
  value: PropTypes.shape({
    store: PropTypes.shape(storeInfoPropTypes),
  }).isRequired,
};

export default StoreInformationBlock;
