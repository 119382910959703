/* eslint-disable*/

// function from flowbox documentation
// https://help.getflowbox.com/en/articles/3569385-embedding-a-flow-in-a-react-application

export const loadFlowbox = () =>
  new Promise(resolve => {
    (function (d, id) {
      if (!window.flowbox) {
        var f = function () {
          f.q.push(arguments);
        };
        f.q = [];
        window.flowbox = f;
      }
      if (d.getElementById(id)) {
        return resolve();
      }
      var s = d.createElement('script'),
        fjs = d.scripts[d.scripts.length - 1];
      s.id = id;
      s.async = true;
      s.src = 'https://connect.getflowbox.com/flowbox.js';
      fjs.parentNode.insertBefore(s, fjs);
      resolve();
    })(document, 'flowbox-js-embed');
  });
