import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clamp from 'lodash/clamp';

import useCollapsingElementHeight from '@common/hooks/useCollapsingElementHeight';

import {
  ContentWrapper,
  Content,
  Divider,
  Title,
  TitleButtonWrapper,
  ToggleButton,
  TopHr,
} from './Accordion.styled';

const MIN_DURATION = 250;
const MAX_DURATION = 800;
const DURATION_PER_PX = 0.4;

const questionSchema = {
  mainEntity: {
    itemScope: true,
    itemProp: 'mainEntity',
    itemType: 'https://schema.org/Question',
  },
  mainEntityName: {
    itemProp: 'name',
  },
  acceptedAnswer: {
    itemScope: true,
    itemProp: 'acceptedAnswer',
    itemType: 'https://schema.org/Answer',
  },
  acceptedAnswerText: {
    itemProp: 'text',
  },
};

export function Accordion({
  children,
  title,
  isFirstItem,
  isQuestionAnswer,
  titleComponent,
  noBottomDivider,
}) {
  const [isToggled, setIsToggled] = useState(false);
  const contentRef = useRef(null);
  const maxHeight = useCollapsingElementHeight(contentRef);

  const duration = `${clamp(
    (contentRef?.current?.scrollHeight || 0) * DURATION_PER_PX,
    MIN_DURATION,
    MAX_DURATION
  )}ms`;

  return (
    <React.Fragment>
      {isFirstItem && <TopHr />}
      <ContentWrapper
        {...(isQuestionAnswer ? questionSchema.mainEntity : {})}
        data-testid="accordionWrapper"
      >
        <TitleButtonWrapper onClick={() => setIsToggled(!isToggled)}>
          {titleComponent || (
            <Title {...(isQuestionAnswer ? questionSchema.mainEntityName : {})}>{title}</Title>
          )}
          <ToggleButton
            isSmall
            isToggled={isToggled}
          />
        </TitleButtonWrapper>
        <Content
          ref={contentRef}
          maxHeight={maxHeight}
          duration={duration}
          isToggled={isToggled}
          {...(isQuestionAnswer ? questionSchema.acceptedAnswer : {})}
        >
          <div {...(isQuestionAnswer ? questionSchema.acceptedAnswerText : {})}>{children}</div>
        </Content>
      </ContentWrapper>
      {!noBottomDivider && <Divider />}
    </React.Fragment>
  );
}

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  isFirstItem: PropTypes.bool,
  title: PropTypes.string,
  isQuestionAnswer: PropTypes.bool,
  titleComponent: PropTypes.element,
  noBottomDivider: PropTypes.bool,
};

Accordion.defaultProps = {
  isFirstItem: false,
  title: '',
  isQuestionAnswer: false,
  titleComponent: undefined,
  noBottomDivider: false,
};

export default Accordion;
