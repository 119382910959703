import styled from 'styled-components';

import {
  centeredGridColumns, getGridBase, getThemeColor, media,
} from '@utils/styled';

import Typography from '@common/components/Typography';
import { CMSBlockContainer } from '@common/components/CMSComponents/Container.styled';

export const Heading = styled(Typography).attrs({
  variant: 'h4',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 1)};
`;

export const Title = styled(Typography).attrs({
  variant: 'h6',
})`
  color: ${getThemeColor('grey.0')};
`;

export const Subtitle = styled(Typography).attrs({
  variant: 'paragraph1',
})`
  color: ${getThemeColor('bluegrey.60')};
  font-weight: 500;
  line-height: 1.2;
`;

export const CaptionWrapper = styled.div`
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'equal', 4)};
`;

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-column: span ${({ singleColumn }) => (singleColumn ? 12 : 6)};
  color: ${getThemeColor('bluegrey.60')};
  ${media.from880down`
    grid-column: span 12;
  `};
`;

export const VideoWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 0;
  ${({ theme }) => theme.getFluidSpacing('margin-right', 'widthBased', 0, true)};
  ${({ theme }) => theme.getFluidSpacing('margin-left', 'widthBased', 0, true)};
  padding-bottom: 65%;

  ${media.from640up`
    margin-left: 0;
    margin-right: 0;
  `}

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

export const Container = styled(CMSBlockContainer)`
  ${media.from640up`
    grid-column: ${centeredGridColumns(12)};
  `}

  ${media.from1200up`
    grid-column: ${centeredGridColumns(10)};
  `}
`;

export const GridContainer = styled.div`
  ${getGridBase(12)}
  ${({ theme }) => theme.getFluidSpacing('grid-gap', 'widthBased', 1)};
`;
