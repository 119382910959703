import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import { theme } from '@themes/main';

import Product from '@common/containers/Product';
import { LAYOUT_COLUMN } from '@common/constants/ui';

import { useLookbookSliderContext } from './lookbookSliderContext';
import { Item } from './LookbookProductsListSlider.styled';

const SingleLookbookSliderItem = ({ product, sliderIndex, productIndex }) => {
  const { refs } = useLookbookSliderContext();

  return (
    // eslint-disable-next-line no-return-assign
    <Item ref={el => (refs.current[productIndex] = { el, slideIndex: sliderIndex })}>
      <ThemeProvider
        theme={{
          ...theme,
          layoutPreference: LAYOUT_COLUMN,
        }}
      >
        <Product
          imageWidths={[491, 450, 380, 340, 280, 491, 491, 480, 375]}
          {...product}
        />
      </ThemeProvider>
    </Item>
  );
};

SingleLookbookSliderItem.propTypes = {
  product: PropTypes.shape({ productColorId: PropTypes.string }).isRequired,
  sliderIndex: PropTypes.number.isRequired,
  productIndex: PropTypes.number.isRequired,
};

export default SingleLookbookSliderItem;
