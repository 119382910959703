import styled from 'styled-components';

import { centeredGridColumns, media } from '@utils/styled';

export const ButtonWrapper = styled.div`
  display: flex;
  grid-column: ${centeredGridColumns(12)};
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'scale', 3, true)};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'section', 3)};

  ${media.from640up`
    grid-column: ${centeredGridColumns(10)};
  `}

  ${media.from1200up`
    grid-column: ${centeredGridColumns(8)};
  `}
`;
