import { useMedia } from 'react-use';

export const useShouldRenderCarousel = vouchersCount => {
  const isLargeDesktop = useMedia('(min-width: 1600px)', false);
  const isDesktop = useMedia('(min-width: 1024px)', false);
  const isTablet = useMedia('(min-width: 500px)', false);

  if (isLargeDesktop) {
    return vouchersCount > 4;
  }

  if (isDesktop) {
    return vouchersCount > 3;
  }

  if (isTablet) {
    return vouchersCount > 2;
  }

  return vouchersCount > 1;
};
