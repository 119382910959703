import React from 'react';
import PropTypes from 'prop-types';

import LazyImg from '@common/components/LazyImg';

import {
  ImageWrapper,
  Label,
  LabelBackdrop,
  CardContainer,
  Name,
  Position,
} from './PersonCard.styled';

const PersonCard = ({
  name, position, statement, image,
}) => {
  if (statement === null && image === null) {
    return null;
  }

  return (
    <CardContainer>
      {statement && (
        <ImageWrapper>
          <LazyImg
            src={statement}
            alt={name}
            aspectRatios="4:5"
          />
        </ImageWrapper>
      )}
      {statement === null && image !== null && (
        <ImageWrapper>
          <LazyImg
            src={image.url}
            alt={image.altText}
            aspectRatios="4:5"
            breakpoints={[640, 560, 480, 400, 0]}
            widths={[600, 570, 500, 420, 350]}
          />
          {(name || position) && (
            <LabelBackdrop>
              <Label>
                {name && <Name>{name}</Name>}
                {position && <Position>{position}</Position>}
              </Label>
            </LabelBackdrop>
          )}
        </ImageWrapper>
      )}
    </CardContainer>
  );
};

PersonCard.propTypes = {
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  statement: PropTypes.string,
  image: PropTypes.shape({
    altText: PropTypes.string,
    height: PropTypes.number,
    title: PropTypes.string,
    url: PropTypes.string,
    width: PropTypes.number,
  }),
};

PersonCard.defaultProps = {
  statement: '',
  image: null,
};

export default PersonCard;
