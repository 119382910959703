import { useTranslation } from 'next-i18next';
import differenceInDays from 'date-fns/differenceInDays';
import endOfDay from 'date-fns/endOfDay';
import PropTypes from 'prop-types';
import React from 'react';

import { formatDate } from '@utils/date';

import { VOUCHER_EXPIRED, VOUCHER_REDEEMED } from '@common/constants/vip';

import { BonWrapper } from './Bon.styled';
import { InvalidCard } from './InvalidCard';
import { ValidCard } from './ValidCard';

function Bon({
  priceTag, redeemed, onInvalidCardClick, validUntil,
}) {
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const todayEOD = endOfDay(new Date());
  const validUntilEOD = endOfDay(validUntil);
  const redeemedDateEOD = endOfDay(redeemed);
  const isExpired = todayEOD > validUntilEOD;

  if (isExpired || redeemed) {
    const dateFormatted = redeemed ?
      formatDate(redeemedDateEOD, currentLanguage) :
      formatDate(validUntilEOD, currentLanguage);

    const message = redeemed ?
      t('redeemedOn', 'Eingelöst am {{date}}', { date: dateFormatted }) :
      t('expiredOn', 'Abgelaufen am {{date}}', { date: dateFormatted });

    return (
      <BonWrapper>
        <InvalidCard
          message={message}
          priceTag={priceTag}
          onClick={() => {
            onInvalidCardClick(redeemed ? VOUCHER_REDEEMED : VOUCHER_EXPIRED);
          }}
        />
      </BonWrapper>
    );
  }

  const daysLeft = differenceInDays(validUntilEOD, todayEOD) + 1;
  const validUntilFormatted = formatDate(validUntil, currentLanguage);
  const bottomTitle = t('validUntil', 'Gültig bis {{date}}', { date: validUntilFormatted });

  return (
    <BonWrapper>
      <ValidCard
        priceTag={priceTag}
        bottomTitle={bottomTitle}
        daysLeft={daysLeft}
      />
    </BonWrapper>
  );
}

Bon.propTypes = {
  onInvalidCardClick: PropTypes.func.isRequired,
  priceTag: PropTypes.number,
  redeemed: PropTypes.instanceOf(Date),
  validUntil: PropTypes.instanceOf(Date),
};

Bon.defaultProps = {
  priceTag: undefined,
  redeemed: undefined,
  validUntil: undefined,
};

export default Bon;
