import styled, { keyframes } from 'styled-components';

import { getThemeColor } from '@utils/styled';

import Typography from '@common/components/Typography';

const infiniteScrollAnimation = keyframes`
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
`;

export const StoreNewsBanner = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  width: 100%;
  ${({ theme }) => theme.getFluidSpacing('padding', 'equal', 14)};
  background-color: ${getThemeColor('grey.0')};
`;

export const StoreNewsBannerText = styled(Typography)`
  margin-bottom: 0;
  color: ${getThemeColor('grey.100')};
  white-space: nowrap;
  animation: ${infiniteScrollAnimation} 50s linear infinite;

  span {
    ${({ theme }) => theme.getFluidSpacing('margin-right', 'equal', 4)};
  }
`;
