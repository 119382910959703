import styled from 'styled-components';

export const Wrapper = styled.div`
  grid-column: 1 / -1;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  grid-column: span 12;
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'section', 3)};

  a::after {
    content: none !important;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
