import styled from 'styled-components';

import { getThemeColor } from '@utils/styled';

import LazyImg from '@common/components/LazyImg';

export const Container = styled.div`
  position: relative;
  height: 100%;
`;

export const StyledLazyImg = styled(LazyImg)`
  ${({ visible }) => visible && 'z-index: 2;'}
  ${({ previous }) => previous && 'z-index: 1;'}
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  background-color: ${getThemeColor('bluegrey.95')};
  transition: opacity ${({ duration }) => duration}ms ease-in;
  pointer-events: none;

  picture {
    display: block;
    width: 100%;
    height: 100%;
  }

  img {
    height: 100%;
    object-fit: cover;
  }
`;

export const ActiveImage = styled(LazyImg)`
  visibility: hidden;
`;
