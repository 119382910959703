import styled from 'styled-components';

import { getThemeColor } from '@utils/styled';

import Typography from '@common/components/Typography';
import { CMSBlockContainer } from '@common/components/CMSComponents/Container.styled';

export const Container = styled(CMSBlockContainer)`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 1)};
`;

export const Title = styled(Typography).attrs({
  variant: 'h5',
})`
  margin-bottom: 2rem;
`;

export const Item = styled.div`
  border-bottom: 1px solid ${getThemeColor('bluegrey.90')};
  padding: 2rem 0;

  &:first-child {
    border-top: 1px solid ${getThemeColor('bluegrey.90')};
  }
`;

export const ItemsContainer = styled.div``;

export const ItemTitle = styled(Typography).attrs({
  variant: 'body1',
})`
  margin-bottom: 0;
`;

export const ItemSubtitle = styled(Typography).attrs({
  variant: 'body2',
})`
  margin-top: 0.9rem;
  margin-bottom: 0;
  color: ${getThemeColor('bluegrey.60')};
`;
