import styled from 'styled-components';

import { getThemeColor } from '@utils/styled';

import Typography from '@common/components/Typography';

export const SectionWrapper = styled.section`
  margin-top: 8rem;
  margin-bottom: 8rem;
`;

export const Title = styled(Typography).attrs({
  customAs: 'h2',
  variant: 'h4',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 6)}
  color: ${getThemeColor('bluegrey.60')};
  text-align: center;
`;
