import styled from 'styled-components';

export const Container = styled.div``;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GridContainer = styled.div``;

export const ButonWrapper = styled.div`
  display: flex;
  justify-content: center;
  grid-column: span 12;
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'section', 3)};

  a::after {
    content: none !important;
  }
`;
